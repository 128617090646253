import React from 'react';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer className="footer text-faded text-center py-2 shadow1">
      <div className="row">
        <div className="col-12 col-md-4">
          <ul className="footer-list">
            <li className="text-center"><Link className="text-faded" to='/'>Accuel</Link></li>
            <li className="text-center"><Link className="text-faded" to='/relais-de-lavance-actualites'>Actualités</Link></li>
            <li className="text-center"><Link className="text-faded" to='/traiteur-mariage-hautes-alpes'>Service traiteur le "Resto'roul"</Link></li>
            <li className="text-center"><Link className="text-faded" to='/restaurant-le-relais-de-lavance'>Le restaurant / Bar</Link></li>
            <li className="text-center"><Link className="text-faded" to='/epicerie-le-relais-de-lavance'>L'épicerie</Link></li>
            <li className="text-center"><Link className="text-faded" to='/horaires-le-relais-de-lavance'>Nos horaires</Link></li>
            <li className="text-center"><Link className="text-faded" to='/contact-le-relais-de-lavance'>Nous contacter</Link></li>
          </ul>
        </div>
        <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center">
          <p className="m-0"><strong>Le relais de l'avance</strong></p>
        </div>
        <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center">
          <p className="m-0"><strong>La Gravière</strong></p>
          <p className="m-0 small">05130 Saint-Étienne-le-Laus</p>
          <p className="m-0 small">04 92 21 82 01</p>
          <p className="m-0 small"><a href="mailto:t.lamb05@laposte.net">t.lamb05@laposte.net</a></p>
        </div>
      </div>
    </footer>
  );
}
