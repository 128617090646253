import React, { Component } from 'react';
import config from '../../config';
import { Link } from 'gatsby';
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  render() {
    const { openMenu } = this.state;
    const { activeLink } = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-dark py-lg-4 shadow3" id="mainNav">
        <div className="container-fluid">

          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler ml-auto mr-auto  ${openMenu ? '' : 'collapsed'}`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav text-center mx-auto">
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'home' ? 'active' : ''
                }`}
              >
                <Link className="nav-link text-uppercase text-expanded" to="/">
                  Accueil
                </Link>
              </li>
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'news' ? 'active' : ''
                }`}
              >
                <Link className="nav-link text-uppercase text-expanded" to="/relais-de-lavance-actualites">
                  Actualités
                </Link>
              </li>
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'traiteur' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded"
                  to="/traiteur-mariage-hautes-alpes"
                >
                  Service traiteur "resto'roul"
                </Link>
              </li>
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'restaurant' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded"
                  to="/restaurant-le-relais-de-lavance"
                >
                  Le restaurant / Bar
                </Link>
              </li>
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'epicerie' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded"
                  to="/epicerie-le-relais-de-lavance"
                >
                  L'épicerie
                </Link>
              </li>
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'horaire' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded"
                  to="/horaires-le-relais-de-lavance"
                >
                  Nos horaires
                </Link>
              </li>
              <li
                className={`nav-item px-lg-4 ${
                  activeLink === 'contact' ? 'active' : ''
                }`}
              >
                <Link
                  className="nav-link text-uppercase text-expanded"
                  to="/contact-le-relais-de-lavance"
                >
                  Nous contacter
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
